<template>
  <div class="container">

    <div class="adviser-choose mt-4 mb-4">
      <h4 class="text-center mb-4">Выбор эдвайзера</h4>


      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Курс</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyCourse">
            <option v-for="(sCourse, sCourseIndex) in [{value: 0, name:'Выберите курс'},...studyCourses]"
                    :key="sCourseIndex"
                    :value="sCourse.value">
              {{ sCourse.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyLevelId">
            <option
                v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...adviserChoose_form.studyLevels]"
                :value="item.id"
                :key="index">{{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4" v-if="studyCourse&&studyLevelId">
        <label class="col-md-3 col-form-label">Образовательная программа</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="educationProgramId">
            <option
                v-for="(item, index) in [{id: 0, education_speciality_name: 'Выберите ОП', language_name: 'язык', admission_year:'год' }, ...adviserChoose_form.educationPrograms]"
                :value="item.id"
                :key="index">
              {{ item.education_speciality_code }} {{ item.education_speciality_name }} -
              {{ item.language_name }} - {{ item.admission_year }}
            </option>
          </select>
        </div>
      </div>

<!--      <div class="row mt-4" v-if="educationProgramId != 0">-->
<!--        <div class="col-md-3">Группы</div>-->
<!--        <div class="col-md-9" v-if="!loadingGroup">-->
<!--          <div v-if="adviserChoose_form.studentGroups.length">-->
<!--            <select class="form-control form-select" v-model="groupId">-->
<!--              <option-->
<!--                  v-for="(item, index) in [{id: 0, name: 'Выберите группу'}, ...adviserChoose_form.studentGroups]"-->
<!--                  :value="item.id"-->
<!--                  :key="index">{{ item.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div v-else class="text-danger">-->
<!--            В данной образовательной программе нет групп-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->




      <div class="student-groups mt-4" v-if="adviserChoose_form.studentGroups.length != 0">


      <DataTable :value="adviserChoose_form.studentGroups" showGridlines
                 stripedRows responsiveLayout="scroll">

        <Column field="name" header="Название группы"></Column>
        <Column header="Выбор эдвайзера">
          <template #body="{data}">
            <select class="form-control form-select"
                    @input="changeAdviser(data.adviser_choose_id, data.id, $event)">
              <option
                  v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...adviserChoose_form.pps]"
                  :value="item.pps_id"
                  :selected="item.pps_id === data.adviser_id"
                  :key="index">{{ item.lastname }} {{ item.firstname }}
              </option>
            </select>
          </template>
        </Column>


      </DataTable>



        <div class="text-center mt-2 mb-4">

          <button type="button" class="btn btn-primary m-1 mb-4" @click="submitAdviserChoose"
                  :disabled="btnSaveStatus">
            <span v-if="btnSaveStatus" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            {{ btnSaveStatus ? 'Сохранение' : 'Сохранить' }}
          </button>
        </div>
      </div>




    </div>
  </div>
</template>


<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "AdviserChoose",
  data() {
    return {
      studyLevelId: 0,
      studyCourse: 0,
      educationProgramId: 0,
      groupId: 0,
      //loadingGroup: false,
      btnSaveStatus: false,
      studyCourses: [
        {
          value: 1,
          name: '1'
        },
        {
          value: 2,
          name: '2'
        },
        {
          value: 3,
          name: '3'
        },
        {
          value: 4,
          name: '4'
        },
      ],
      adviserChooseForm: [],
      updateAdviserChooseForm: []
    }
  },
  computed: {
    ...mapState('adviserChoose', ['adviserChoose_form']),

  },
  watch: {
    studyCourse() {
      this.getEducationPrograms()
    },
    studyLevelId() {
      this.getEducationPrograms()
    },
    async educationProgramId(id) {
      //this.loadingGroup = true
      await this.GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID(id)
      //this.loadingGroup = false
    }
  },
  methods: {
    ...mapActions('adviserChoose', ['GET_STUDY_LEVELS', 'GET_EDUCATION_PROGRAMS', 'GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID',
      'GET_PPS', 'POST_ADVISER_CHOOSE', 'GET_ADVISER_CHOOSE', 'PUT_ADVISER_CHOOSE']),
    ...mapMutations('adviserChoose', ['CLEAR_STATE']),
    getEducationPrograms() {
      if (this.studyLevelId && this.studyCourse) {
        this.GET_EDUCATION_PROGRAMS({studyLevelId: this.studyLevelId, studyCourse: this.studyCourse})
      }
    },

    changeAdviser(adviser_choose_id, student_group_id, e, val = 'value') {
      console.log(student_group_id, 'student_group_id')
      console.log(adviser_choose_id, 'adviser_choose_id')
      const pps_id = e.target[val]

      if (adviser_choose_id == null) {

        if (!this.adviserChooseForm.find(i=>i.student_group_id == student_group_id)) {
          this.adviserChooseForm.push({
            student_group_id: student_group_id,
            pps_id: pps_id
          })
        }
        else {
          let updateAdviserChoose = this.adviserChooseForm.find(i=>i.student_group_id == student_group_id)
          updateAdviserChoose['pps_id'] = pps_id
        }

        console.log(this.adviserChooseForm, 'adviserChooseForm')

      }
      else {

        if (!this.updateAdviserChooseForm.find(i=>i.id == adviser_choose_id)) {
          this.updateAdviserChooseForm.push({
            id: adviser_choose_id,
            student_group_id: student_group_id,
            pps_id: pps_id
          })
        }
        else {
          let updateAdviser = this.updateAdviserChooseForm.find(i=>i.id == adviser_choose_id)
          updateAdviser['pps_id'] = pps_id
        }


        console.log(this.updateAdviserChooseForm, 'updateAdviserChooseForm')
      }




    },


    async submitAdviserChoose() {


      console.log(this.adviserChooseForm, 'adviserChooseForm')
      if (this.adviserChooseForm.length != 0){
        let data = await this.POST_ADVISER_CHOOSE(this.adviserChooseForm)
        if (data) {

          //await this.GET_ADVISER_CHOOSE()
          this.$message({text: 'Сохранено'})
          this.btnSaveStatus = false

          this.CLEAR_STATE()
          this.adviserChooseForm = []
          this.studyLevelId = 0
          this.studyCourse = 0
          this.educationProgramId = 0
          this.groupId = 0


        }
      }

      if (this.updateAdviserChooseForm.length != 0){
        let data = await this.PUT_ADVISER_CHOOSE(this.updateAdviserChooseForm)
        if (data) {

          //await this.GET_ADVISER_CHOOSE()
          this.$message({text: 'Обновлено'})
          this.btnSaveStatus = false

          this.CLEAR_STATE()
          this.updateAdviserChooseForm = []
          this.studyLevelId = 0
          this.studyCourse = 0
          this.educationProgramId = 0
          this.groupId = 0
        }
      }

    }

  },

  async mounted() {
    await this.GET_STUDY_LEVELS()
    await this.GET_PPS()

  }
}
</script>

<style scoped>
</style>